<template>
  <div>
    <profileParts />
    <a :href="link.url" class="flix-btn flix-btn-default" target="_blank"><flixIcon id="share" /> {{ $t('message.goTo', { name: $t('message.dataCenter') }) }}</a>
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') }
  },
  props: {},
  data () {
    return {
      variables: this.$getUserVariables(),
      link: false
    }
  },
  computed: {

  },
  mounted () {
    this.getSharedLink()
  },
  methods: {
    getSharedLink () {
      this.$flix_post({
        url: 'cloud/usershare',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) { this.link = ret.data[1] }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
  iframe
    width: 100%
</style>
